import styled from 'styled-components';

export const AppLayoutContainer = styled.div`
  height: 100%;
`;

export const DemoIframe = styled.iframe`
  width: 100%;

  height: 1100px;

  overflow: hidden;

  margin-bottom: 50px;

  border: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 900px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  margin: ${({ theme }) => theme.core.sizing[2]}px 0 ${({ theme }) => theme.core.sizing[5]}px 0;

  h2 {
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    span {
      color: ${({ theme }) => theme.core.colors.primary.green.medium};
    }
  }

  p {
    color: ${({ theme }) => theme.core.colors.greyscale.dark};

    br {
      display: none;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    p {
      br {
        display: block;
      }
    }
  }
`;
